import React, { useState } from 'react';
import Layout from '../../../../components/layout';
import SectionDescriptionNew from '../../../../components/section-description-new';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';
import pageData from '../../../../data/summits/2017';
import { Grid, Typography, Box } from '@material-ui/core';
// import Gallery from '../../../../data/gallery.json';
import ImageCarousel from '../../../../components/image-carousel';
import Fade from 'react-reveal/Fade';

const YouthPage = () => {
  // const year = '2017';
  // const album = 'youth-circle';
  const [files, setFiles] = useState();

  return (
    <Layout>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='YOUTH INITIATIVE'
            isViewAll
            viewAllText='Back To GMIS2017'
            baseLink='/summits/2017'>
            <SectionDescriptionNew
              left
              image
              filename={pageData['youthCircle'].image}
              text={pageData['youthCircle'].text}
            />
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {pageData['youthCircle'].subSections.map((_data, index) => (
              <Grid item xs={12} key={index}>
                <Fade bottom>
                  <Typography variant='h6' color='textPrimary'>
                    <Box fontWeight='fontWeightBold'>{_data.title}</Box>
                  </Typography>
                  <Typography paragraph color='textSecondary'>
                    {_data.text}
                  </Typography>
                </Fade>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            {files ? (
              <SectionContainerLayoutWithFilter title='GALLERY' isViewAll baseLink='/galleries'>
                <Fade bottom>
                  <ImageCarousel imageFilenames={files} />
                </Fade>
              </SectionContainerLayoutWithFilter>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default YouthPage;
