import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import ImageContainer from '../../components/image-container';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Scrollbar from '../scrollbar';
import CarouselVideoContainer from '../video-carousel';

const ImageCarousel = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [mediaLength, setMediaLength] = useState(
    props.imageFilenames
      ? props.imageFilenames.length
      : props.videoLinks[0]
      ? props.videoLinks[0].length
      : props.videoLinks.length
  );

  useEffect(() => {
    if (props.videoLinks) {
      setMediaLength(props.videoLinks[0] ? props.videoLinks[0].length : props.videoLinks.length);
    }
  }, [props.videoLinks && props.videoLinks[0]]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1 === mediaLength ? 0 : prevActiveStep + 1));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? mediaLength - 1 : prevActiveStep - 1));
  };

  return (
    <>
      <Box position='relative'>
        <Grid container alignItems='center' wrap='nowrap'>
          <Box clone position='absolute' zIndex='appBar' left={0}>
            <Grid item>
              <ChevronLeftIcon style={{ fontSize: '60px', cursor: 'pointer' }} onClick={handleBack} color='primary' />
            </Grid>
          </Box>
          {props.imageFilenames ? (
            <Grid item xs={12}>
              {props.isImgTag ? (
                <img
                  src={`${process.env.GATSBY_CMS_API_URL}/static/${props.imageFilenames[activeStep]}`}
                  alt={props.imageFilenames[activeStep] || '#GMIS2021'}
                  width='100%'
                />
              ) : (
                <ImageContainer
                  filename={props.imageFilenames[activeStep]}
                  alt={props.imageFilenames[activeStep] || '#GMIS2021'}
                />
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CarouselVideoContainer
                videoUrl={props.videoLinks.length !== 0 && props.videoLinks[0][activeStep]}
                showControls
                autoPlay
              />
            </Grid>
          )}
          <Box clone position='absolute' zIndex='appBar' right={0}>
            <Grid item>
              <ChevronRightIcon style={{ fontSize: '60px', cursor: 'pointer' }} onClick={handleNext} color='primary' />
            </Grid>
          </Box>
        </Grid>
        <Box mt={5}>
          <Scrollbar width='100%' height='100%'>
            {props.imageFilenames ? (
              <Box display='flex' style={{ cursor: 'pointer' }}>
                {props.imageFilenames.map((_file, index) => (
                  <Box mb={5} key={index} minWidth={100} mx={2} onClick={() => setActiveStep(index)}>
                    {props.isImgTag ? (
                      <img
                        src={`${process.env.GATSBY_CMS_API_URL}/static/${_file}`}
                        alt={_file || '#GMIS2021'}
                        width='100%'
                      />
                    ) : (
                      <ImageContainer filename={_file} altText={_file || '#GMIS2021'} />
                    )}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box display='flex' style={{ cursor: 'pointer' }}>
                {props.videoLinks.length !== 0 &&
                  props.videoLinks.map((_video) => {
                    return _video.map((_url, index) => {
                      return (
                        <Box key={index} minWidth={100} mx={2} onClick={() => setActiveStep(index)}>
                          <CarouselVideoContainer videoUrl={_url} />
                        </Box>
                      );
                    });
                  })}
              </Box>
            )}
          </Scrollbar>
        </Box>
      </Box>
    </>
  );
};

export default ImageCarousel;
